/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

body {
	padding-right: 0 !important;
}

.card-stock {
	// flex: 1 0 250px;
	margin: 0;
	align-items: center;
}

.card-stock .btn:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.card-stock .btn:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.card-stock input.form-control {
	border-radius: 0;
}

.top-cards .card-deadline,
.top-cards .card-commercial {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.top-cards .card-deadline .card,
.top-cards .card-commercial .card {
	flex: 1;
}

.card-deadline {
	font-size: 1.5rem;
	line-height: 2.5rem;
}

.card-deadline .card-body {
	// padding-left: 0;
}

.clock {
	@media (max-width: 767.98px) {
		font-size: 3.5rem;
		margin-bottom: 1rem;
	}
	font-size: 4.5rem;
	// padding: 1.5rem;
}

.btn {
	padding-left: 2rem;
	padding-right: 2rem;
}

h1,
.h1 {
	font-size: 2.3rem;
}

.alert-body {
	font-size: 1.2rem;
	// padding: 1rem 2rem !important;
}

.btn-lg {
	font-size: 1.5rem;
	font-weight: 500;
	padding: 1rem 2.4rem;
}

.card-main .card-header,
.card-user-stock .card-header {
	padding: 2rem 3.5rem;
}

.card-main .card-body {
	padding: 3rem 4rem;
}

.card-main > .card-body,
.card-client > .card-body {
	padding: 0 3.5rem;
}

.card-stock .card-body {
	background: #efefef;
	border-radius: 15px;
}

.card-stock .card-body .css-hlu0h4-singleValue {
	color: var(--bs-body-color);
}

body.dark-layout .card-stock .card-body {
	background: #212739;
}

body.dark-layout .card-stock .card-body .css-hlu0h4-singleValue {
	color: #fff;
}
.card-main.card .card-footer,
.card-client.card .card-footer,
.card-product-description .card-footer {
	border-top: 0;
}

.card-footer {
	padding: 2rem 3.5rem;
}

.gap-2rem {
	gap: 2rem;
}

.p-2rem {
	padding: 2rem;
}

.avatar:not(.avatar-s) .avatar-content {
	width: 48px;
	height: 48px;
}

.avatar-stats {
	cursor: auto;
}

.bg-gradient-primary {
	background: linear-gradient(92.25deg, #37b2cf 1.51%, #318ba5 99.06%);
}
.card-client .client-detail ul li {
	margin: 0.5rem 0;
}

.card-timeleft .avatar .avatar-content,
.card-stat .avatar .avatar-content {
	width: 64px;
	height: 64px;
}

.breadcrumb {
	align-items: center;
}

.breadcrumb .separator {
	margin: 0.5rem;
}

body.dark-layout .card-product-description .card-body {
	background: #212739;
}

.card-product-description .card-body {
	background-color: #efefef;
	border-radius: 15px;
}

.rdt_TableCol {
	padding-left: 3.5rem !important;
	padding-right: 3.5rem !important;
}

.rdt_TableCell {
	padding: 2.5rem 3.5rem !important;
}

.invoice-list-wrapper .avatar .avatar-content {
	width: 40px;
	height: 40px;
}

.card-user-stock .card-stock {
	flex: 1 0 230px;
	max-width: 230px;
}

.card-client {
	// min-width: 500px;
	// height: calc(100vh - 108.5px - 28px - 47px);
	// position: fixed;
}

.card-client .card-body {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.bg-gradient-primary:active {
	background-image: linear-gradient(151deg, #2592a9, #2fb5d2);
}

.card-header label {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	margin-right: 0.5rem !important;
}

.stockProgress .progress {
	height: 8px;
}

[dir] .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	padding: 0.72rem 1.5rem !important;
}

[dir] .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
	padding: 0.72rem 1.5rem !important;
}

.oui ~ span {
	margin: 0 !important;
}

// .rdt_TableCol_Sortable:has(> .oui) {
// 	margin: 10px !important;
// 	color: red;
// }

.statisticsCard > .card {
	height: calc(100% - 2rem);
}

.fade-out {
	opacity: 0;
	transition: opacity 0.5s;
}

.brand-logo {
	height: 3.5rem;
}

.brand-text {
	font-family: 'ZapfHumnst-BT' !important;
	letter-spacing: 0.35rem !important;
	text-transform: uppercase !important;
	color: #2fb5d2 !important;
}

// .card {
// 	margin-bottom: 0;
// }

.invoice-list-dataTable header {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.back-button {
	cursor: pointer;
	margin-top: 1.5rem;
	margin-left: 1.5rem;
}
.back-button:hover {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.unselectable {
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-default {
	cursor: default !important;
}

.bg-client {
	color: white;
	background-color: #3298da !important;
}

.bg-commercial {
	color: white;
	background-color: #ff7f50 !important;
}

.bg-distributor {
	color: white;
	background-color: #9b59b6 !important;
}

.bg-admin {
	color: white;
	background-color: #fcbc04 !important;
}

.avatar.bg-light-client {
	color: #3298da !important;
}

.avatar.bg-light-commercial {
	color: #ff7f50 !important;
}

.avatar.bg-light-distributor {
	color: #9b59b6 !important;
}

.avatar.bg-light-admin {
	color: #fcbc04 !important;
}

.bg-light-client {
	background: rgb(50 152 218 / 12%) !important;
}

.bg-light-commercial {
	background: rgb(255 127 80 / 12%) !important;
}

.bg-light-distributor {
	background: rgb(240 29 182 / 12%) !important;
}

.bg-light-admin {
	background: rgb(252 188 4 / 12%) !important;
}

.color-client {
	color: #3298da !important;
}

.color-commercial {
	color: #ff7f50 !important;
}

.color-distributor {
	color: #9b59b6 !important;
}

.color-admin {
	color: #fcbc04 !important;
}

.flex-1 {
	flex: 1;
}

.stock-container {
	display: flex;
	grid-gap: 1.5rem;
	gap: 1.5rem;
	@media screen and (max-width: 1150px) {
		flex-direction: column;
		grid-gap: 0 !important;
		gap: 0 !important;
	}
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child {
	margin-left: 1.5rem;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
	margin-left: 1.5rem;
}

body.dark-layout .react-loading-skeleton {
	--base-color: #2f364c;
	--highlight-color: #333b4d;
}

.notifications-center .form-switch .form-check-label .switch-icon-left {
	transform: translate(5px, 1px) !important;
}

.card-client .form-switch .form-check-label .switch-icon-right {
	transform: translate(-2px, 1px) !important;
}

.card-footer {
	padding-top: 0;
}

.card-user-stock .card-footer {
	border-top: 0;
}

.dropdown-menu .dropdown-item {
	width: 100%;
}

#search-invoice {
	width: initial;
}

.search-products-wrap {
	z-index: 1;
	@media screen and (min-width: 900px) {
		translate: 0 3.5rem;
	}
	@media screen and (max-width: 899px) {
		padding-top: 2rem;
		justify-content: center !important;
	}
}

.card-body.products {
	min-height: 200px;
}

.card-body.products .card-header {
	position: initial;
}

.invoice-list-table-header .form-select {
	min-width: 150px;
}

.brand-logo {
	max-width: fit-content;
}

.fit-content {
	min-width: fit-content;
	min-height: fit-content;
}

.stock-body {
	padding-left: 4rem !important;
	padding-right: 4rem !important;
}

.invoice-list-wrapper header {
	background: none !important;
}

body.dark-layout .card-stock .card-body {
	background: #212739;
}

.billingRequestButton {
	height: 60px;
	font-size: 16pt;
}

.dropdown-user-link .avatar .avatar-content {
	font-size: 1rem;
}

.color-danger {
	color: #ea5455 !important;
}

.color-warning {
	color: #ff9f43 !important;
}

.color-success {
	color: #28c76f !important;
}

.stock-container-disabled {
	position: absolute;
	width: 100%;
	height: -webkit-fill-available;
	background: #020305b5;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

@import '@src/@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '@src/@core/scss/base/components/include'; // Components includes

.swal2-container {
	.swal2-popup {
		border-radius: $card-border-radius;
		padding-bottom: 1.5rem;

		// close button
		.swal2-close:focus {
			outline: none;
			box-shadow: none;
		}

		// content
		.swal2-content {
			color: $body-color;
		}

		// header styling
		.swal2-title {
			font-size: $h3-font-size;
			font-weight: $headings-font-family;
			color: $headings-color;
		}

		// remove box-shadow on focus of buttons
		button.swal2-confirm:focus {
			box-shadow: none;
		}

		// input
		input.swal2-input {
			height: $input-height;
			font-size: $font-size-base;
			padding: $input-padding-y $input-padding-x;
			color: $body-color;
			border-color: $input-border-color;
			border-radius: $border-radius;
			line-height: $line-height-base;
			&:focus {
				border-color: $primary !important;
				box-shadow: $input-focus-box-shadow;
			}
		}

		// steps
		.swal2-progress-steps {
			.swal2-progress-step {
				background-color: $primary;
				&.swal2-active-progress-step {
					~ .swal2-progress-step-line,
					~ .swal2-progress-step {
						background-color: tint-color($primary, 15%);
					}
				}
			}
			.swal2-progress-step-line {
				background-color: $primary;
			}
		}

		// pre code
		pre {
			min-height: 1.35rem;
			margin-top: 0.5rem;
		}

		// footer
		.swal2-footer {
			border-color: $border-color;
			color: $body-color;
		}
	}
}

// Dark Layout
.dark-layout {
	.swal2-container {
		.swal2-popup .swal2-title {
			color: $theme-dark-headings-color;
		}

		.swal2-modal {
			background-color: $theme-dark-card-bg;

			.swal2-header {
				.swal2-title {
					color: $theme-dark-headings-color;
				}

				.swal2-icon-text {
					color: inherit;
				}

				.swal2-success-circular-line-left,
				.swal2-success-circular-line-right,
				.swal2-success-fix,
				.swal2-animate-success-icon {
					background-color: $theme-dark-card-bg !important;
				}
			}

			input.swal2-input {
				border-color: $theme-dark-border-color;
				color: $theme-dark-body-color;
			}
			.swal2-content,
			.swal2-html-container {
				color: $theme-dark-body-color;
			}

			pre,
			code {
				color: $theme-dark-body-color;
			}
			.swal2-input {
				background-color: $theme-dark-card-bg;
			}

			// footer
			.swal2-footer {
				border-color: $theme-dark-border-color;
				color: $theme-dark-body-color;
			}
		}
		.swal2-validation-message {
			background-color: $theme-dark-body-bg;
			color: $theme-dark-body-color;
		}
	}
}

.shepherd-footer {
	flex-direction: row-reverse;
}

.text-justify {
	text-align: justify;
}

.area-accordion-header button {
	padding: 0 !important;
	background-color: transparent !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	color: #2fb5d2 !important;
}

.area-accordion-header button::after {
	margin-left: 0.2rem;
	fill: #2fb5d2 !important;
}

.accordion-margin.accordion .area-accordion-item.accordion-item {
	box-shadow: none !important;
}

.area-accordion-body .accordion-body {
	padding: 0 !important;
}